import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import Container from '@/components/Container';
import Image from '@/components/Image';
import { Body, Headline } from '@/components/Text';

const trophyItems = [
	{ height: 89, src: 'images/home/trophy/am-2020.png', width: 123 },
	{ height: 82, src: 'images/home/trophy/am-2021.png', width: 123 },
	{ height: 93, src: 'images/home/trophy/am-2022.png', width: 123 },
	{ height: 79, src: 'images/home/trophy/motoTrader-2022.png', width: 133 },
];

const Trophy: FC = () => {
	const { t } = useTranslation('home');

	return (
		<Container
			variant="tertiary-contrast"
			className="py-4 gap-x-2 lg:gap-x-4 gap-y-2 flex flex-col md:flex-row lg:items-center"
		>
			<div>
				<Headline weight="bold">{t('trophy.title')}</Headline>
				<Body className="mt-0.5">{t('trophy.text')}</Body>
			</div>
			<div className="grid grid-cols-4 gap-1 md:gap-2 justify-between items-center">
				{/* TODO: move images to d3 */}
				{trophyItems.map(({ src, width, height }) => (
					<Image key={src} src={src} height={height} width={width} />
				))}
			</div>
		</Container>
	);
};

export default Trophy;
