import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { faStar } from '@fortawesome/pro-regular-svg-icons';
import { useRouter } from 'next/router';
import { TFunction, useTranslation } from 'next-i18next';
import { FC, Fragment } from 'react';

import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Image from '@/components/Image';
import { Body, Title2 } from '@/components/Text';
import useCurrencyFormat, { FormatOptions } from '@/services/Hooks/useCurrencyFormat';

const stepItems = (
	t: TFunction,
	currencyFormat: (value: number, options?: FormatOptions) => string
) => [
	{
		headline: t('metrics.one.headline'),
		id: 1,
		// TODO: move images to d3
		img: { height: 91, src: 'images/home/metric/tag.svg', width: 97 },
		text: t('metrics.one.text', {
			limit: currencyFormat(3000, { style: 'decimal' }),
		}),
		title: t('metrics.one.title'),
	},
	{
		headline: t('metrics.two.headline'),
		id: 2,
		img: { height: 92, src: 'images/home/metric/key.svg', width: 119 },
		text: t('metrics.two.text'),
		title: t('metrics.two.title'),
	},
	{
		headline: t('metrics.three.headline'),
		id: 3,
		img: { height: 86, src: 'images/home/metric/chat.svg', width: 103 },
		text: t('metrics.three.text'),
		title: t('metrics.three.title'),
	},
	{
		headline: t('metrics.four.headline'),
		id: 3,
		img: { height: 101, src: 'images/home/metric/like.svg', width: 130 },
		text: t('metrics.four.text'),
		title: (
			<>
				{t('metrics.four.title')}
				<Icon variant="reverse" iconName={faStar} className="ml-0.5 text-n3 md:text-n5" />
			</>
		),
	},
];

const Metrics: FC = () => {
	const currencyFormat = useCurrencyFormat();
	const { t } = useTranslation('home');
	const { push } = useRouter();

	return (
		<div className="rounded-3xl lg:bg-reverse lg:border lg:border-tone-contrast lg:py-2.5 mt-2.5 md:mt-3 lg:mt-4 lg:px-2">
			<div className="grid grid-cols-1 lg:grid-cols-4 gap-x-2 gap-y-1 ">
				{stepItems(t, currencyFormat).map(({ id, img, title, headline, text }, idx, list) => (
					<Fragment key={id}>
						<div className="flex flex-row md:flex-col gap-x-1 flex-row-reverse items-center">
							<div className="min-h-6.5 flex-none">
								<Image src={img.src} height={img.height} width={img.width} />
							</div>
							<div className="md:mt-0.5 ">
								<Body weight="bold" className="min-h-3">
									{headline}
								</Body>
								<Title2 variant="reverse" className="flex items-center">
									{title}
								</Title2>
								<Body className="mt-0.5">{text}</Body>
							</div>
						</div>

						{idx < list.length - 1 && <hr className="lg:hidden border-tertiary-contrast" />}
					</Fragment>
				))}
			</div>
			<div className="mt-2 grid lg:justify-center lg:mx-auto">
				<Button
					onClick={() => push('/registration/dealership')}
					icon={faLongArrowRight}
					size="medium"
					width="content"
					label={t('metrics.button')}
				/>
				<Body weight="bold" className="mt-0.5">
					{t('metrics.footnote')}
				</Body>
			</div>
		</div>
	);
};
export default Metrics;
