import { FC } from 'react';

import Aspects from '@/modules/Home/Aspects';
import Benefits from '@/modules/Home/Benefits';
import EuropeReviews from '@/modules/Home/EuropeReviews';
import LandingHero from '@/modules/Home/LandingHero';
import Trophy from '@/modules/Home/Trophy';

const International: FC = () => (
	<>
		<LandingHero />
		<Aspects />
		<Benefits />
		<EuropeReviews />
		<Trophy />
	</>
);

export default International;
