import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import Card from '@/components/Card';
import Image from '@/components/Image';
import { LargeButton } from '@/components/Text';
import {
	LocalizationAwareObjectType,
	useLocalizationAwareObject,
} from '@/services/Hooks/useLocalizationAwareObject';

const brandList = ({ locale }: LocalizationAwareObjectType) => {
	switch (locale) {
		case 'gb':
		case 'ie':
			return [
				'images/home/brand/vauxhall.svg',
				'images/home/brand/mercedes.svg',
				'images/home/brand/peugeot.svg',
				'images/home/brand/volkswagen.svg',
				'images/home/brand/bmw.svg',
				'images/home/brand/ford.svg',
				'images/home/brand/jaguar.svg',
				'images/home/brand/land-rover.svg',
			];
		default:
			return [
				'images/home/brand/audi.svg',
				'images/home/brand/mercedes.svg',
				'images/home/brand/skodaBlack.svg',
				'images/home/brand/volkswagen.svg',
				'images/home/brand/bmw.svg',
				'images/home/brand/ford.svg',
				'images/home/brand/jaguar.svg',
				'images/home/brand/land-rover.svg',
			];
	}
};

const Brands: FC = () => {
	const { t } = useTranslation('home');
	const { locale } = useRouter();
	const brands = useLocalizationAwareObject(brandList, 'home', locale);

	return (
		<Card className="flex flex-col md:flex-row items-center">
			<LargeButton weight="bold" className="w-full md:w-3/12">
				{t('attraction.brand')}
			</LargeButton>
			<div className="grid grid-cols-4 gap-x-1 md:gap-x-0.5 md:grid-cols-8 gap-y-1.5 md:gap-y-0 md:justify-between w-full mt-1.5 md:mt-0 md:px-1.5 md:w-9/12">
				{brands.map((img) => (
					<Image remote key={img} src={img} height={64} width={64} />
				))}
			</div>
		</Card>
	);
};
export default Brands;
