import { useTranslation } from 'next-i18next';
import { OrganizationJsonLd } from 'next-seo';
import { FC } from 'react';

const OrganizationMarkup: FC = () => {
	const { t } = useTranslation('seo');

	return (
		<OrganizationJsonLd
			type={t('organizationMarkup.type')}
			logo={t('organizationMarkup.logo')}
			name={t('organizationMarkup.name')}
			url={t('organizationMarkup.url')}
			address={{
				addressCountry: t('organizationMarkup.address.addressCountry'),
				addressLocality: t('organizationMarkup.address.addressLocality'),
				postalCode: t('organizationMarkup.address.postalCode'),
				streetAddress: t('organizationMarkup.address.streetAddress'),
				type: t('organizationMarkup.address.type'),
			}}
			contactPoint={[
				{
					areaServed: t('organizationMarkup.contactPoint.areaServed', { returnObjects: true }),
					availableLanguage: t('organizationMarkup.contactPoint.availableLanguage', {
						returnObjects: true,
					}),
					contactType: t('organizationMarkup.contactPoint.contactType'),
					email: t('organizationMarkup.contactPoint.email'),
					telephone: t('organizationMarkup.contactPoint.telephone'),
					type: t('organizationMarkup.contactPoint.type'),
				},
			]}
			sameAs={[
				t('organizationMarkup.sameAs.twitter'),
				t('organizationMarkup.sameAs.linkedin'),
				t('organizationMarkup.sameAs.facebook'),
				t('organizationMarkup.sameAs.instagram'),
				t('organizationMarkup.sameAs.trustpilot'),
				t('organizationMarkup.sameAs.crunchbase'),
			]}
			aggregateRating={{
				ratingValue: t('organizationMarkup.rating.ratingValue'),
				reviewCount: t('organizationMarkup.rating.reviewCount'),
				type: t('organizationMarkup.rating.type'),
			}}
		/>
	);
};

export default OrganizationMarkup;
