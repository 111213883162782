import { FC } from 'react';

import Container from '@/components/Container';
import ImageContainer from '@/components/ImageContainer';
import Metrics from '@/modules/Home/Aspects/Metrics';
import WhyBumper from '@/modules/Home/Aspects/WhyBumper';

const Aspects: FC = () => (
	<ImageContainer variant="diamond-tone-contrast-5" width="full" className="pt-2 pb-2 lg:pb-4">
		<Container width="default" variant="transparent">
			<WhyBumper />
			<Metrics />
		</Container>
	</ImageContainer>
);

export default Aspects;
