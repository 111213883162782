import { FC } from 'react';

import Container from '@/components/Container';
import CustomerGets from '@/modules/Home/Benefits/CustomerGets';
import Gains from '@/modules/Home/Benefits/Gains';

const Benefits: FC = () => (
	<Container variant="reverse" className="pt-2 pb-2 lg:pb-4">
		<CustomerGets />
		<Gains />
	</Container>
);

export default Benefits;
