import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { Body, LargeButton, Title1 } from '@/components/Text';

const CustomerGets: FC = () => {
	const { t } = useTranslation('home');

	return (
		<>
			<Body weight="bold" variant="reverse">
				{t('customerGets.headline')}
			</Body>
			<LargeButton weight="bold">{t('customerGets.subtitle')}</LargeButton>
			<Title1 className="uppercase mt-0.5" variant="reverse">
				{t('customerGets.title')}
			</Title1>
		</>
	);
};
export default CustomerGets;
