import { GetServerSideProps, GetServerSidePropsContext, NextPageLayout } from 'next';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextSeo } from 'next-seo';
import { useEffect } from 'react';

import OrganizationMarkup from '@/components/SEO/OrganizationMarkup';
import { STATIC_LAYOUT } from '@/constants/layouts';
import { IRELAND, UNITED_KINGDOM } from '@/constants/locales';
import HomeForBusiness from '@/modules/Home/Business';
import HomeForDrivers from '@/modules/Home/Drivers';
import { HomePageTypes } from '@/modules/Home/home';
import International from '@/modules/Home/International';
import { returnDecodedAccessTokenValues } from '@/services/Auth/AccessToken';
import useApplication from '@/services/Hooks/useApplication';
import useMarketplaceController from '@/services/Hooks/useMarketplaceController';
import canonicalGenerator from '@/services/Utils/Seo/canonicalGenerator';
import generateLanguageAlternates from '@/services/Utils/Seo/generateLanguageAlternates';

const HomePage: NextPageLayout<HomePageTypes.HomePage> = ({ locale, isStaff }) => {
	const { t } = useTranslation('homePage');
	const { asPath } = useRouter();
	const languageAlternates = generateLanguageAlternates(asPath);
	const { flushState } = useMarketplaceController();
	const { flushApplicationState } = useApplication();
	const isInternational = ![UNITED_KINGDOM, IRELAND].includes(locale);

	useEffect(() => {
		flushState();
		flushApplicationState();
	}, []);

	const renderHomePage = () => {
		if (isInternational) {
			return <International />;
		}

		if (isStaff) {
			return <HomeForBusiness />;
		}

		return <HomeForDrivers />;
	};

	return (
		<div className="font-text overflow-hidden">
			<OrganizationMarkup />
			<NextSeo
				title={t('seo:homePage.title')}
				description={t('seo:homePage.description')}
				canonical={canonicalGenerator(locale, asPath)}
				languageAlternates={languageAlternates}
			/>
			{renderHomePage()}
		</div>
	);
};

HomePage.layoutType = STATIC_LAYOUT;

export const getServerSideProps: GetServerSideProps = async (ctx: GetServerSidePropsContext) => {
	const { isStaff } = returnDecodedAccessTokenValues(undefined, ctx);

	const { locale = UNITED_KINGDOM } = ctx;
	return {
		props: {
			locale,
			...(await serverSideTranslations(locale, [
				'common',
				'home',
				'footer',
				'forms',
				'navbar',
				'seo',
				'acquisition',
				'howitworks',
			])),
			isStaff,
			isdefaultTheme: true,
		},
	};
};

export default HomePage;
