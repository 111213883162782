import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { faStar } from '@fortawesome/pro-regular-svg-icons';
import { useRouter } from 'next/router';
import { TFunction, useTranslation } from 'next-i18next';
import { FC, Fragment } from 'react';

import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Image from '@/components/Image';
import { LargeBody } from '@/components/Text';

const stepItems = (t: TFunction) => [
	{
		headline: t('gains.one.headline'),
		id: 1,
		// TODO: move images to d3
		img: { height: 109, src: 'images/home/gain/zero.svg', width: 139 },
		text: t('gains.one.text'),
		title: t('gains.one.title'),
	},
	{
		headline: t('gains.two.headline'),
		id: 2,
		img: { height: 110, src: 'images/home/gain/flexible.svg', width: 164 },
		text: t('gains.two.text'),
		title: t('gains.two.title'),
	},
	{
		headline: t('gains.three.headline'),
		id: 3,
		img: { height: 86, src: 'images/home/gain/fast.svg', width: 127 },
		text: t('gains.three.text'),
		title: t('gains.three.title'),
	},
	{
		headline: t('gains.four.headline'),
		id: 3,
		img: { height: 120, src: 'images/home/gain/digital.svg', width: 126 },
		text: t('gains.four.text'),
		title: (
			<>
				{t('gains.four.title')}
				<Icon variant="reverse" iconName={faStar} className="ml-0.5 text-n3 md:text-n5" />
			</>
		),
	},
];

const Gains: FC = () => {
	const { t } = useTranslation('home');
	const { push } = useRouter();

	return (
		<>
			<div className="mt-3 grid grid-cols-1 lg:grid-cols-4 gap-x-2 gap-y-1 ">
				{stepItems(t).map(({ id, img, headline, text }, idx, list) => (
					<Fragment key={id}>
						<div className="flex flex-row md:flex-col gap-x-1 ">
							<div className="md:min-h-8 min-w-6 flex items-start md:items-end">
								<Image src={img.src} height={img.height} width={img.width} />
							</div>
							<div className="md:mt-1">
								<LargeBody weight="bold">{headline}</LargeBody>
								<LargeBody>{text}</LargeBody>
							</div>
						</div>

						{idx < list.length - 1 && <hr className="lg:hidden border-tertiary-contrast" />}
					</Fragment>
				))}
			</div>
			<div className="mt-3 grid lg:justify-center lg:mx-auto">
				<Button
					onClick={() => push('/howitworks')}
					icon={faLongArrowRight}
					size="medium"
					width="content"
					label={t('gains.button')}
				/>
			</div>
		</>
	);
};
export default Gains;
