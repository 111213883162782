import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import Button from '@/components/Button';
import Container from '@/components/Container';
import ImageContainer from '@/components/ImageContainer';
import { Body, LargeBody, LargeTitle } from '@/components/Text';
import TrustpilotLabel from '@/modules/Home/TrustpilotLabel';

const LandingHero: FC = () => {
	const { t } = useTranslation('home');
	const { push } = useRouter();

	return (
		<ImageContainer variant="repairman" width="full" className="pb-6 pt-7">
			<Container width="default" variant="transparent">
				<TrustpilotLabel />
				<LargeTitle className="mt-1.5 md:mt-2 uppercase xl:w-1/2 md:w-2/3">
					{t('landing.title')}
				</LargeTitle>
				<LargeBody className="mt-0.5 md:mt-1 mb-1.5 max-w-md">{t('landing.subtitle')}</LargeBody>
				<Button
					onClick={() => push('/registration/dealership')}
					width="content"
					size="medium"
					label={t('landing.button')}
					icon={faLongArrowRight}
				/>
				<Body weight="bold" className="mt-0.5">
					{t('landing.text')}
				</Body>
			</Container>
		</ImageContainer>
	);
};

export default LandingHero;
