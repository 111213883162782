import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import Card from '@/components/Card';
import Container from '@/components/Container';
import ImageContainer from '@/components/ImageContainer';
import { Headline } from '@/components/Text';
import TrustpilotWidget from '@/components/Widgets/Trustpilot';
import Brands from '@/modules/Home/Attraction/Brands';

const EuropeReviews: FC = () => {
	const { t } = useTranslation('home');

	return (
		<ImageContainer variant="round-secondary" width="full" className="py-4 px-1">
			<Container variant="transparent">
				<div className="grid md:grid-cols-2 items-center mb-3">
					<Headline variant="secondary" weight="bold">
						{t('review')}
					</Headline>
					<Card className="py-1 px-1">
						<TrustpilotWidget height="22px" theme="light" textColor="black" type="horizontal" />
					</Card>
				</div>
				<Brands />
			</Container>
		</ImageContainer>
	);
};

export default EuropeReviews;
