import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { Body, Title1 } from '@/components/Text';

const WhyBumper: FC = () => {
	const { t } = useTranslation('home');

	return (
		<>
			<Body weight="bold" variant="reverse">
				{t('whyBumper.why')}
			</Body>
			<Body weight="bold">{t('whyBumper.subtitle')}</Body>
			<Title1 className="uppercase mt-0.5" variant="reverse">
				{t('whyBumper.title')}
			</Title1>
			<Body className="mt-0.5">{t('whyBumper.footnote')}</Body>
		</>
	);
};
export default WhyBumper;
