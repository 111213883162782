interface LanguageAlternate {
	hrefLang: string;
	href: string;
}

type SupportedLocales = {
	[key: string]: string;
};

const generateLanguageAlternates = (path: string, blog?: any): LanguageAlternate[] => {
	try {
		const languageAlternates: LanguageAlternate[] = [];

		const supportedLocales: SupportedLocales = {
			de: 'de-DE',
			en: 'en-GB',
			es: 'es-ES',
			ie: 'en-IE',
			nl: 'nl-NL',
		};

		const locales = Object.keys(supportedLocales);

		if (blog && blog?.attributes?.hreflang) {
			const hrefLang = blog?.attributes?.hreflang || [];

			hrefLang.forEach((item: { href: string; hreflang: string }) => {
				languageAlternates.push({
					href: `${item.href}`,
					hrefLang: item.hreflang,
				});
			});

			// Add x-default to blog if not present in blogHrefLang
			const hasXDefault = hrefLang.some(
				(item: { href: string; hreflang: string }) => item.hreflang === 'x-default'
			);

			if (!hasXDefault) {
				languageAlternates.push({
					href: `${process.env.NEXT_PUBLIC_SITE_URL}${hrefLang[0].href}`,
					hrefLang: 'x-default',
				});
			}
		} else if (!blog) {
			locales.forEach((localeKey) => {
				const hrefLang = supportedLocales[localeKey];
				const isDefaultLocale = localeKey === 'en';
				const href = `${process.env.NEXT_PUBLIC_SITE_URL}${
					isDefaultLocale ? '' : `/${localeKey}`
				}${path}`;
				languageAlternates.push({ href, hrefLang });
			});

			languageAlternates.push({
				href: `${process.env.NEXT_PUBLIC_SITE_URL}`,
				hrefLang: 'x-default',
			});
		}
		return languageAlternates;
	} catch (error) {
		console.error('An error occurred while generating language alternates:', error);
		return [];
	}
};

export default generateLanguageAlternates;
